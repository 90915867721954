.wrapper {
    position: absolute; z-index: 200;
    top: 0; left: 0;
    width: 100vw;
}





.wrapper.enter {
    z-index: 100;
    opacity: 0;
}

.wrapper.enter-active {
    opacity: 1;
    transition-delay: 1.5s;
}





.wrapper.exit {
    z-index: 200;
    opacity: 1;
}

.wrapper.exit-active {
    opacity: 0;
    transition-delay: 1.5s;
}




.page {
    position: relative;
    top: 0;
}