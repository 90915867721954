#lodingPic{
    width: 10%;
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
    animation: rolling 3S infinite linear;
    -webkit-animation: rolling 3S infinite linear;
}
@media all and (min-width:750px){
    #lodingPic{
        width: 10%;
    }

}
@keyframes rolling{
    0%{
        transform:  scale(.5);
        -webkit-transform:  scale(.5);
        -moz-transform:  scale(.5);
        -ms-transform:  scale(.5);
        -o-transform:  scale(.5);
}
50%{
    transform:  scale(1);
    -webkit-transform:  scale(1);
    -moz-transform:  scale(1);
    -ms-transform:  scale(1);
    -o-transform:  scale(1);
}
    100%{
        transform: scale(.5);
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
}
}
@-moz-keyframes rolling{
    0%{
        transform:  scale(.5);
        -webkit-transform:  scale(.5);
        -moz-transform:  scale(.5);
        -ms-transform:  scale(.5);
        -o-transform:  scale(.5);
}
50%{
    transform:  scale(1);
    -webkit-transform:  scale(1);
    -moz-transform:  scale(1);
    -ms-transform:  scale(1);
    -o-transform:  scale(1);
}
    100%{
        transform: scale(.5);
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
}
}
@-webkit-keyframes rolling{
    0%{
        transform:  scale(.5);
        -webkit-transform:  scale(.5);
        -moz-transform:  scale(.5);
        -ms-transform:  scale(.5);
        -o-transform:  scale(.5);
}
50%{
    transform:  scale(1);
    -webkit-transform:  scale(1);
    -moz-transform:  scale(1);
    -ms-transform:  scale(1);
    -o-transform:  scale(1);
}
    100%{
        transform: scale(.5);
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
}
}