header {
    position: fixed; z-index:10000;
    top: 0px; left: 0px;
    width: 100%; height: 70px;
}

#header-bg {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 80px;
    background: linear-gradient(to right, rgba(0,0,0,0.95), rgba(20,20,20,0.95));
    opacity: 0;
    transition-duration: 1s;
}

header.reduced #header-bg {
    height: 55px;
    opacity: 1;
}





a#logo {
    position: relative; float: left; z-index: 101;
    margin: 24.5px 20px 0 20px;
    height: 31px; width: 184px;
    transform: translateZ(0);
    transition-duration: 1s;
}

a#logo img {
    height: 100%; width: 100%;
    /* filter: invert(1); */
}

header.reduced a#logo {
    height: 17px; 
    width: 100px;
    margin-top: 19px;
}







#menu-btn {
    position: absolute; z-index: 100;
    top: 10px; right: 20px;
    padding: 10px;
    width: 30px; height: 30px;
    transition-duration: 1s;
    cursor: pointer;
}

#menu-btn span {
    position: absolute;
    top: 24px; right: 0;
    height: 2px; width: 30px;
    background: #ddd;
    border-radius: 1px;
    overflow: hidden;
    transition-duration: 0.5s; transition-timing-function: ease-in-out;
    transform-origin: center;
}

#menu-btn span:hover {
    background: white;
}

#menu-btn span:first-child {
    top: 14px;
    transform: rotate(0deg);
}

#menu-btn span:last-child {
    top: 35px;
    transform: rotate(0deg);

}

#menu-btn.expanded span:first-child {
    top: 50%;
    transform: rotate(-136deg);
}

#menu-btn.expanded span:nth-child(2) {
    width: 0;
    opacity: 0;
}

#menu-btn.expanded span:last-child {
    top: 50%;
    transform: rotate(136deg);
}

header.reduced #menu-btn {
    top: 0; right: 10px;
    transform: scale(0.7);
    transition-duration: 1s;
}








#nav-bg {

}

#nav-bg.expanded {

}







nav {
    position: fixed;
    top: 0; right: 0;
    width: 500px; height: 100%;
    background: rgba(0,0,0,0.94);
    transform: translateX(100%);
    transition-duration: 0.5s; transition-timing-function: cubic-bezier(0.8,0,0.2,1);
}

.nav-inner {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

nav a {
    display: block;
    padding: 10px;
    color: #ccc; font-size: 20px;  text-align: center;
    transition-duration: 0.3s;
}

nav a:hover {
    color: white;
}

nav a.active, nav a.active:hover {
    color: #ccc; font-weight: bold;
    transition-duration: 0.3s;
}

nav.expanded {
    transform: translateX(0);
}

@media all and (max-width: 760px) {

    nav {
        width: 100vw;
    }

    nav a {
        font-size: 16px;
    }

}



